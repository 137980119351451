'use client'

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

import { Container } from '@/_components/Container'

const faqs = [
  {
    question: 'Is there a free plan available?',
    answer:
      'No, there is no free plan. However, by choosing the subscription option, you can take advantage of a 7-day free trial, which can be canceled at any time without any obligations.',
  },
  {
    question: 'Who is behind Nora?',
    answer:
      'Nora is developed by a team in Stockholm consisting of three AI programmers, a successful entrepreneur, and a seasoned marketer, all collaborating to build the best AI conversational tutor for learning Japanese.',
  },
  {
    question: 'Is Nora suitable for beginners?',
    answer:
      'Nora is ideal for individuals with a basic understanding of Japanese who want to enhance their conversational skills. When creating an account, you will select your proficiency level, and the AI will also continuously learn and adapt to match your specific skill level.',
  },
  {
    question: 'Is the payment process secure?',
    answer:
      'Yes, we ensure secure financial transactions through Stripe, which handles all payment processing. Rest assured, we do not store any of your credit card information.',
  },
  {
    question: 'Can I get a refund?',
    answer:
      "You can cancel the free trial at any time. If you forget to cancel and need a refund, we can process it within 14 days, provided you haven't used the service after the trial period ended. For one-time purchases, we offer a 7-day full refund policy. After that, the same terms as the subscription apply. For assistance, please contact us at support@hellonora.ai.",
  },
  {
    question: 'What payment options do you accept?',
    answer: 'For subscriptions we accept all major credit cards.',
  },
  {
    question: 'Does Nora have a mobile app?',
    answer:
      'No, Nora is built desktop first. However, you can access the platform on your mobile device through your browser.',
  },
  {
    question: 'What browsers can I use?',
    answer:
      'Nora is compatible with all modern browsers, including Chrome, Firefox, Safari, and Edge. It also works seamlessly on mobile browsers for both iPhone and Android devices.',
  },
  {
    question: 'What AI models do you use?',
    answer:
      'Nora uses a mix of different AI models from OpenAI, Anthropic and Meta. Every model has a specific task they excel at and together they create a powerful AI tutor.',
  },
  {
    question: 'How is Nora different from using ChatGPT directly?',
    answer:
      'Nora provides a tailored experience specifically designed for language learning with ChatGPT. It includes additional features such as bilingual support, an in-app dictionary, Furigana display, topics/role-plays and Japanese-specific settings to enhance your learning experience effectively.',
  },
  {
    question: 'How can I get in touch with you?',
    answer: 'You can reach us at support@hellonora.ai',
  },
]

export function Faqs() {
  return (
    <Container id='faq' aria-label='Frequently asked questions'>
      <div className='pt-8 pb-16 divide-y divide-gray-900/10 md:py-12'>
        <h3 className='font-bold max-w-4xl text-h3 text-regalBlu text-center sm:text-left'>
          Frequently asked questions
        </h3>
        <dl className='mt-6 space-y-4 divide-y divide-gray-900/10'>
          {faqs.map((faq) => (
            <Disclosure as='div' key={faq.question} className='pt-4'>
              {({ open }) => (
                <>
                  <dt>
                    <DisclosureButton className='flex w-full items-start justify-between text-left text-gray-900'>
                      <span className='text-base font-semibold leading-7'>
                        {faq.question}
                      </span>
                      <span className='ml-6 flex h-7 items-center'>
                        {open ? (
                          <MinusIcon className='h-6 w-6' aria-hidden='true' />
                        ) : (
                          <PlusIcon className='h-6 w-6' aria-hidden='true' />
                        )}
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as='dd' className='mt-2 pr-12'>
                    <p className='text-base leading-7 text-gray-600'>
                      {faq.answer}
                    </p>
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Container>
  )
}
