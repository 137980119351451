'use client'

import { useIsMobile } from '@/_hooks/useIsMobile'

interface HeroArtGalleryWrapperProps {
  children: React.ReactNode
}

export const HeroArtGalleryWrapper = ({
  children,
}: HeroArtGalleryWrapperProps) => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return null
  }

  return children
}
