import { Button } from '@/_components/Button'
import { NORA_SIGN_UP_URL } from '@/_utils/urls'

interface PricingCardProps {
  title: string
  total?: string
  price: string
  period: string
  cta: string
  discount?: string
  outline?: boolean
}

export function PricingCard({
  title,
  total,
  price,
  period,
  cta,
  discount,
  outline,
}: PricingCardProps) {
  return (
    <div
      className='bg-[#ebf0ff] rounded-lg w-full min-w-[172px] max-w-[310px] sm:w-[310px] pb-4'
      style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
    >
      <div className='relative flex flex-col gap-6 w-full px-4 sm:gap-7 h-full justify-between'>
        {discount && (
          <div
            className='absolute -top-[30px] sm:-top-10 right-4 font-medium bg-red-500 text-white rounded-lg sm:rounded-xl px-1.5 py-0.5 text-base sm:px-3 sm:py-1 sm:text-lg'
            style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
          >
            {discount}
          </div>
        )}
        <div className='flex bg-regalBlue w-full px-3 py-1 rounded-b-xl h-14 justify-center items-center text-nowrap'>
          <p className='text-white text-base sm:text-xl'>{title}</p>
        </div>
        <div className='relative flex flex-col items-center sm:mt-8 sm:gap-3'>
          {total && (
            <p className='sm:absolute -top-2 sm:-top-8 text-red-500 text-base'>
              {total}
            </p>
          )}
          <p className='text-[54px]/[58px] sm:text-8xl text-regalBlue font-bold'>
            {price}
          </p>
          <p className='text-gray-500 text-base'>{period}</p>
        </div>
        <div className='flex flex-col w-full self-center text-nowrap'>
          {outline ? (
            <Button
              href={NORA_SIGN_UP_URL}
              className='start-signup-button'
              data-app-origin='marketing'
              color='red'
              outline
            >
              {cta}
            </Button>
          ) : (
            <Button
              href={NORA_SIGN_UP_URL}
              className='start-signup-button'
              data-app-origin='marketing'
              color='red'
            >
              {cta}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
