'use client'

import Image from 'next/image'
import { useEffect, useState } from 'react'

import TableSvg from '@/_assets/images/Table.svg'
import TableMobileSvg from '@/_assets/images/TableMobile.svg'
import { Container } from '@/_components/Container'

export function Comparison() {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)

  useEffect(() => {
    const xsMediaQuery = window.matchMedia('(max-width: 540px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setIsSmallScreen(matches)
    }

    onMediaQueryChange(xsMediaQuery)
    xsMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      xsMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <Container id='comparison' aria-label='Comparison Table'>
      <div className='flex flex-col items-center text-center w-full py-10 gap-7 md:pt-16 md:pb-14 md:gap-14'>
        <h3 className='font-bold mx-auto max-w-4xl text-h3 text-regalBlue'>
          We created Nora to make speaking practice more accessible for Japanese
          language learners
        </h3>
        <Image
          className='max-w-full shadow-[rgba(149,157,165,0.2)_0px_8px_24px]'
          src={isSmallScreen ? TableMobileSvg : TableSvg}
          alt='Nora Comparison Table'
        />
      </div>
    </Container>
  )
}
