'use client'

import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'

import FeatBilingual from '@/_assets/images/FeatBilingual.png'
import FeatDict from '@/_assets/images/FeatDict.png'
import FeatHome from '@/_assets/images/FeatHome.png'
import FeatSettings from '@/_assets/images/FeatSettings.png'
import { Container } from '@/_components/Container'

const features = [
  {
    title: 'In-app dictionary',
    description:
      "We've built a fully integrated dictionary into the app so you can look up words and replay audio without leaving the page.",
    image: FeatDict,
  },
  {
    title: 'Bilingual and monolingual',
    description:
      'We support both bilingual and monolingual conversation modes, so you can practice your Japanese in the way that works best for you.',
    image: FeatBilingual,
  },
  {
    title: 'Free talk or role-plays',
    description:
      'Whether you’re preparing for a job interview in Japanese or brush up on how to order at a restaurant in Japan, Nora can talk to you about exactly what you want.',
    image: FeatHome,
  },
  {
    title: 'Casual or formal conversation',
    description:
      "Whether you're talking to your boss or your friends, we've got you covered with our conversation modes.",
    image: FeatSettings,
  },
]

export function PrimaryFeatures() {
  const [tabOrientation, setTabOrientation] = useState<
    'horizontal' | 'vertical'
  >('horizontal')

  useEffect(() => {
    const xlMediaQuery = window.matchMedia('(min-width: 1280px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(xlMediaQuery)
    xlMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      xlMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <div className='relative overflow-hidden pt-10 pb-12 bg-blue-600 sm:py-24'>
      <Container
        id='features'
        aria-label='Features for running your books'
        className='relative'
      >
        <div className='max-w-2xl md:mx-auto text-center xl:max-w-none'>
          <h3 className='font-bold mx-auto max-w-4xl text-h3  text-white'>
            Nora is tailored to experienced Japanese learners looking to
            accelerate their speaking fluency
          </h3>
          <p className='mt-6 text-lg text-blue-100'>
            Our features are built around the unique complexities of the
            Japanese language
          </p>
        </div>
        <Tab.Group
          as='div'
          className='mt-7 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 xl:grid-cols-12 xl:pt-0'
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className='-mx-4 flex overflow-x-scroll pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 xl:col-span-5'>
                <Tab.List className='relative z-10 flex gap-x-4 whitespace-nowrap px-4 gap-column sm:mx-auto sm:flex-wrap sm:gap-y-2 sm:justify-center xl:px-0 xl:mx-0 xl:block xl:gap-x-0 xl:gap-y-1 xl:whitespace-normal xl:justify-start'>
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full px-4 py-1 xl:rounded-l-xl xl:rounded-r-none xl:p-6',
                        selectedIndex === featureIndex
                          ? 'bg-white xl:bg-white/10 xl:ring-1 xl:ring-inset xl:ring-white/10'
                          : 'hover:bg-white/10 xl:hover:bg-white/5'
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display ui-not-focus-visible:outline-none text-lg',
                            selectedIndex === featureIndex
                              ? 'text-blue-600 xl:text-white'
                              : 'text-blue-100 hover:text-white xl:text-white'
                          )}
                        >
                          <span className='absolute inset-0 rounded-full xl:rounded-l-xl xl:rounded-r-none' />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm xl:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className='xl:col-span-7'>
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className='relative sm:px-6 xl:hidden'>
                      <div className='absolute -inset-x-4 bottom-[-2.5rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:top-[-7.5rem] sm:rounded-xl' />
                      <p className='relative mx-auto max-w-2xl text-base text-white text-center'>
                        {feature.description}
                      </p>
                    </div>
                    <div className='mt-14 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:mt-10 sm:w-auto xl:mt-0 xl:w-[67.8125rem]'>
                      <Image
                        className='w-full h-auto'
                        src={feature.image}
                        alt='Feature image'
                        sizes='(min-width: 1280px) 67.8125rem, (min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem'
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </div>
  )
}
