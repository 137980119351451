'use client'

import { Container } from '@/_components/Container'

import { PricingCard } from './PricingCard'

export function Pricing() {
  return (
    <Container id='pricing' aria-label='Pricing'>
      <div className='flex flex-col w-full text-center gap-6 py-10 sm:py-14'>
        <h3 className='font-bold mx-auto max-w-4xl text-h3 text-regalBlue'>
          Pricing
        </h3>
        <p className='text-lg tracking-tight text-regalBlue'>
          Choose the plan that works best for you and get instant access to all
          features today.
        </p>
        <div className='flex flex-wrap justify-center items-stretch gap-12 mt-4 xl:gap-8 sm:mt-12'>
          <PricingCard
            title='Monthly Plan'
            price='$25'
            period='/per Month'
            cta='Start your free trial'
            outline
          />
          <PricingCard
            title='Six-month Plan'
            price='$20'
            total='$240 yearly'
            period='/per Month'
            discount='Save 20%'
            cta='Start your free trial'
          />
          <PricingCard
            title='Yearly Plan'
            total='$180 yearly'
            discount='Save 40%'
            price='$15'
            period='/per Month'
            cta='Start your free trial'
            outline
          />
        </div>
      </div>
    </Container>
  )
}
